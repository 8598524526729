// https://www.youtube.com/watch?v=byyHoLPSxSQ
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import { debounce } from "./../../scripts/utils/debounce.js"


gsap.registerPlugin(Flip);


let isOpened = false

let elements = {
  modals : document.querySelectorAll('.js-flipselect'),
  modalCloseButtons: document.querySelectorAll('.js-flipselect-close'),
  currentModal : null,
  listItems : document.querySelectorAll('.js-flipselect-list li')
}


function init() {

  // If empty
  if(!elements.modals.length > 0) return
  

  // Handle Resize
  window.addEventListener("resize", debounce( () => {
    setPositions()
  }, 150 ));


  // Setup modals
  elements.modals.forEach((modal) => {
    modal.addEventListener('click', (e) => {

      if (isOpened)
        return

      setPositions()
      openModal(modal)

    })

  });

  elements.modalCloseButtons.forEach((item) => {
    item.addEventListener('click', (e) => {
        closeModal()
    })
  })


  // Listitems

  elements.listItems.forEach((item) => {
    item.addEventListener('click', (e) => {

      if (!isOpened) return

      elements.currentModal.previousSibling.innerText = e.target.innerText
      closeModal()

    })

  });
}



function windowCloseModal(event) {
  if (isOpened && event.target.contains(elements.currentModal) && event.target !== elements.currentModal) {
    closeModal()
  }
}


function setPositions() {
  elements.modals.forEach((modal) => {
    // Set height
    let list = modal.querySelector('ul')
    modal.style.setProperty('--modal-top-position', '-' + list.offsetHeight / 2 + 'px')
  });
}

function openModal(target) {

  window.addEventListener('click', windowCloseModal)

  let state = Flip.getState(target)
  target.classList.add('--has-open')

  Flip.from(state, {
    duration: 0.1,
    absolute: true,
    simple: true,
    scale: true,
    ease: "power1.out",
    //toggleClass: '--is-open',
    onComplete: () => {
      //target.classList.remove('--is-open')
      target.classList.add('--has-open')
      isOpened = true
      elements.currentModal = target
    }
  });

}

function closeModal() {

  let state = Flip.getState(elements.currentModal)
  elements.currentModal.classList.remove('--has-open')

  Flip.from(state, {
    duration: 0.1,
    absolute: true,
    simple: true,
    scale: true,
    ease: "power1.out",
    onComplete: () => {
      elements.currentModal.classList.remove('--has-open')
      isOpened = false
      elements.currentModal = null
      window.removeEventListener('click', windowCloseModal)
    }
  });

}


export default () => {
  init();
}

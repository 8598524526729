export default () => {
    
    // Fields
    let searchBtn = document.querySelector('.c-global-search-form')
    let searchField = document.querySelector('.c-global-search-form__field')

    let searchWrapper = document.querySelector('.c-global-search')

    if(!searchBtn)
        return
      
    searchBtn.addEventListener('click', (e) => {
        
        // Open
        if (!searchBtn.classList.contains('--open')){
            open();
        } 

    })


    function bodyFn(evt) {
        
        if (evt.target.classList.value == 'c-global-search-form__field' )
            return
        
        close()        
        
    }


    function open() {

        searchBtn.classList.add('--open')
        searchWrapper.classList.add('--open')
        searchField.focus()

        setTimeout( () => {
            document.addEventListener( 'click', bodyFn );
        }, 100 )
    }

    function close() {
        searchField.blur()
        searchBtn.classList.remove('--open')
        searchWrapper.classList.remove('--open')
        document.removeEventListener( 'click', bodyFn );
    }

  



}

export default () => {
    
    let cookie = 'mpbolagen'
    let expire = 365 // days

    
    function getCookie(cookieName) {
        
        let name = cookieName + "="
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';')
        
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
        
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
        
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    function setCookie(cookieName, cookieValue, expirationDays) {
        const date = new Date()
        date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000))
        let expires = "expires=" + date.toUTCString()
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"
    }

    function deleteCookie() {
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    }

    let currentCookie = getCookie(cookie)
      
    if (currentCookie == "") {

        const cookieText = document.querySelector('.j-cookie-text');

        const cookieDiv = document.createElement('div');
        cookieDiv.classList.add('c-cookie');
      
        const header = document.createElement('p');
        header.classList.add('c-cookie__header');
        header.textContent = cookieText.dataset.cookieHeader;

      
        const text = document.createElement('p');
        text.classList.add('c-cookie__text');
        text.textContent = cookieText.dataset.cookieText
        
        const privacyLink = document.createElement('a');
        privacyLink.href = cookieText.dataset.cookieHref;
        privacyLink.textContent = ' ' + cookieText.dataset.cookieMore;
        text.appendChild(privacyLink);
        
        const button = document.createElement('button');
        button.classList.add('c-cookie__button');
        button.textContent = 'Ok';
      
        cookieDiv.appendChild(header);
        cookieDiv.appendChild(text);
        cookieDiv.appendChild(button);
      
        document.body.appendChild(cookieDiv);

        button.addEventListener('click', (e) => {
            currentCookie = true
            
            if (currentCookie != "" && currentCookie != null) {
                setCookie(cookie, currentCookie, expire)
                cookieDiv.remove()
            }
        })
        
    }


    //deleteCookie()


}
import { domReady } from "./utils/domready"
import Cookie from './cookie'
import GlobalSearch from './components/global-search'
import PerfectScrollbar from 'perfect-scrollbar';

// // Pages
import Start from './pages/start'
import Page from './pages/page'
import Category from './pages/category'
import Product from "./pages/product";


class App {

    static start() {
        return new App()
    }

    constructor() {
        Promise
            .all([
                domReady()
            ])
            .then(
                this.init.bind(this)
            )
    }



    init() {
        console.info('🚀App:init')

        // Search expand
        GlobalSearch()

        // Cookie
        Cookie()


        let scrollbar = document.querySelector('.js-scrollbox')

        if (scrollbar) {
            const ps = new PerfectScrollbar('.js-scrollbox', {
                suppressScrollY: true
                // wheelSpeed: 2,
                // wheelPropagation: true,
                // minScrollbarLength: 20
            });
 
            window.addEventListener('resize', () => { 
                ps.update()
            });
        }

        // When sticky, add class
        const el = document.querySelector(".header-bottom")
        const header = document.querySelector(".header")
        const observer = new IntersectionObserver( ([e]) => {            
            header.classList.toggle("is-pinned", e.intersectionRatio < 1)
        }, { 
            threshold: 1.0,
        });

        observer.observe(el);
     
        
        this.content = document.querySelector('.content')
        this.template = this.content.getAttribute('data-template')

        this.pages = {
            start: new Start(),
            page: new Page(),
            category : new Category(),
            product : new Product()
        }
        this.page = this.pages[this.template]
        
        this.page.init()

    }

}

App.start()
const debounce = (func, time) => {
  
  var time = time || 100; // 100 by default if no param
  var timer;
  
  return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
  };
}



export { 
  debounce
}

// function debounce(func, time){
//   var time = time || 100; // 100 by default if no param
//   var timer;
//   return function(event){
//       if(timer) clearTimeout(timer);
//       timer = setTimeout(func, time, event);
//   };
// }
// @link https://codepen.io/aaronbushnell/pen/eGVdzv
export default class Accordion {

    constructor($el) {
        this.$el = $el;
        this.$title = this.$el.querySelector('[data-title]');
        this.$content = this.$el.querySelector('[data-content]');
        this.isOpen = false;
        this.height = 0;

        this.initEvents();
        this.close();
        this.$el.classList.remove('c-accordion--init');
    }

    initEvents() {

        this.$title.addEventListener('click', this.handleClick.bind(this));
        this.$content.addEventListener('transitionend', this.handleTransition.bind(this));
    }

    destroy() {
      //console.log('destroy')
      this.$title.addEventListener('click', this.handleClick);
      this.$content.removeEventListener('transitionend', this.handleTransition);
    }


    handleClick() {
        
        this.height = this.$content.scrollHeight;

        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    close() {
        this.isOpen = false;
        this.$el.classList.remove('--active');
        this.$content.style.maxHeight = `${this.height}px`;

        setTimeout(() => {
            this.$content.style.maxHeight = `${0}px`;
        }, 1);
    }

    open() {
        this.isOpen = true;
        this.$el.classList.add('--active');
        this.$el.classList.remove('--hidden');
        this.$content.style.maxHeight = `${0}px`;
        //   this.$el.scrollIntoView({ 
        //       behavior: 'smooth', 
        //       block: 'center'
        //     });
        const yOffset = (window.innerHeight * 25 / 100) * -1;
        const y = this.$el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
            top: y,
            behavior: 'smooth'
        });


        setTimeout(() => {
            this.$content.style.maxHeight = `${this.height}px`;
        }, 1);
    }

    handleTransition() {
        if (!this.isOpen) {
            this.$el.classList.add('--hidden');
            //this.$el.classList.remove('is-opened');
        } else {
            //this.$el.classList.add('is-opened');
        }

        //this.$content.style.maxHeight = '0px';
    }
}


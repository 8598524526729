import 'mmenu-js';

export default (el) => {

    const navButton = document.querySelector('#js-mobile-nav-button')
    const navMenu = document.querySelector('#js-mobile-nav')


    if (!navMenu) {
        return
    }

    const menu = new Mmenu(navMenu, {
        offCanvas: {
            position : 'left-front', 
        },
        slidingSubmenus: true,
        navbars: {
            content: [
                'prev', 
                'close',
                'title'
            ]
        },
        navbar: {
            title: 'MP bolagen'
        }
    }, {
       
    });

    const api = menu.API
    let panel

    // Mobile Menu
    navButton.addEventListener(
        'click', () => {
            if (panel) {
                panel.classList.remove('mm-panel--opened')
            }
            api.open()
        }
    );


    let items = document.querySelectorAll('[data-panel-menu-index]')

    items.forEach(item => {

        item.addEventListener('click', (e) => {
            e.preventDefault()

            if (panel) {
                panel.classList.remove('mm-panel--opened')
            }

            let index = e.target.getAttribute('data-panel-menu-index')
            panel = document.querySelector('#js-panel-menu-' + index)

            if(panel == null) {
                let href = e.target.getAttribute('href')
                window.location.href = href
            } else {
                panel.classList.add('mm-panel--opened')
                api.open()                
            }

            // if(panel != null) {

            //     panel.classList.add('mm-panel--opened')
            //     api.open()

            // } else {

            //     let href = e.target.getAttribute('href')
            //     window.location.href = href

            // }

        })
    });

}
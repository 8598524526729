import Base from './base'

import Accordion from "./../components/accordians";
import { CategoryKeys, CategoryImages } from "./categories-data";


export default class Category extends Base {
    constructor() {
        super({
            id: 'category',
        })
    }

    init() {
        
        console.log('🚀App:category');

        this.ReadMore();

        // Filter in mobile
        this.ToggleFilter();

        // Accordian
        [...document.querySelectorAll('[data-accordion]') ].forEach(accordian => {
    
            new Accordion( accordian );
        });


        // Todo: Get current category in category-intro.pug
        // kabelstegar, kabelrannor, tradstegar
        // fonsterbankskanaler, uttagsstavar
        // golvboxar, belysningsskenor, stativ19
        // profilerskenor, kabelhallare
        // betongskruv, potentialutjamning
        
        this.CategoryContainer = document.querySelector('.b-category-intro-media')
        let currentCategory = this.CategoryContainer.getAttribute('data-current-category')
        this.CreateCategoryImages(currentCategory)
        this.CategoryHoverEffects()

    }

    CreateCategoryImages(current) {

       // console.log(current)

        const container = document.querySelector('.b-category-intro-media')
        
        // Get current images
        let images
        CategoryKeys.forEach((k, i) => {
            if (k.includes(current)) {
                images = CategoryImages[i]
            }
        })
        
        // Avoid repaints and save all images to DocumentFragment
        const fragment = document.createDocumentFragment();

        // Loop images
        images.forEach(src => {
            const imgElement = document.createElement('img');
            imgElement.src = src;
            imgElement.className = 'b-category-intro-media__img';
            fragment.appendChild(imgElement);
        });

        // Add images
        this.CategoryContainer.appendChild(fragment);

    }

    CategoryHoverEffects() {




        // Elements
        let thumbnails = document.querySelectorAll('img.b-category-intro-media__img')
        let links = document.querySelectorAll('.c-tag-list__link')        
        let linksList = document.querySelector('.c-tag-list')

        let totalIndexLength = 0
        let defaultIndex = 0;
        let activeIndex;
        let defaultLink

        if (thumbnails.length == 1) {
            setActive(defaultIndex)

        } else {

            totalIndexLength = thumbnails.length - 1
            defaultIndex = thumbnails.length - 1;
            activeIndex;

            defaultLink = linksList.querySelector('.--active')

            if (defaultLink) {
                activeIndex = defaultLink.getAttribute('data-thumb')
                defaultIndex = activeIndex           
            }
        }

        setActive(defaultIndex)

        function setActive(index) {

            thumbnails[index].classList.add('--active') 

            if(index < totalIndexLength) {
                links[index].classList.add('--active') 
            }
            
            activeIndex = index


        }

        function removeActive() {
            // Remove class
            thumbnails[activeIndex].classList.remove('--active') 

            if(activeIndex < totalIndexLength) {
                links[activeIndex].classList.remove('--active') 
            }

        }
        

        if (links) {
            
            links.forEach(link => {
                
                link.addEventListener('mouseover', (e) => {
                    let target = link.getAttribute('data-thumb')
                    
                    removeActive()
                
                    setActive(target)
                   
                })

                linksList.addEventListener('mouseleave', (e) => {

                    removeActive()

                    setActive(defaultIndex)

                })

            });
            
        }
    }

    ToggleFilter() {
        // Toggle
        let toggleFilter = document.querySelector('.js-toggle-filter')
        let filterOverlay = document.querySelector('.js-filter-overlay')

        toggleFilter.addEventListener('click', (e) => {
            document.body.classList.toggle('--filter-active')
        })

        filterOverlay.addEventListener('click', (e) => {
            document.body.classList.remove('--filter-active')
        })
    }

    ReadMore() {

        // Read more section
        let btnReadMore = document.querySelector('.js-read-more')
        let sectionReadMore = document.querySelector('#js-section-read-more')

        if(btnReadMore) {

            btnReadMore.addEventListener('click', (e) => {
                e.preventDefault()

                sectionReadMore.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start'
                });
            })
        }

    }
}

const path = "/public/assets/images/"

const CategoryKeys = [
  ['010000', '010001', '010002', '010003', '010004', '010005', '010006'], // Kabelstegar
  ['040000', '040001', '040002', '040003', '040004', '040005', '040006' ], // Kabelrännor/Rännor/Armaturskenor
  ['070000', '070001', '070002', '070003', '070005', '070006'], // Trådstegar
  ['100000', '100001', '100002', '100003' ], // Fönsterbankskanaler
  ['050000', "050001" ], // Uttagsstavar/poster
  ['110000', "110001", '110002' ], // Golvboxar
  ['020000', '020001', '020002', '020003' ], // Belysningsskenor
  ['080000', '080001' ], // 19stativ 
  ['060000', '060001' ], // Profilerskenor
  ['030000', '030001' ], // Kabelhallare
  ['120000' ], // Betongskruv
  ['090000'], // Potentialutjamning
]


const CategoryImages = [
    // Kabelstegar
    [
      path + "/010000/010001.png",
      path + "/010000/010002.png",
      path + "/010000/010003.png",
      path + "/010000/010004.png",
      path + "/010000/010005.png",
      path + "/010000/010006.png",
      path + "/010000/010000.all.png"
    ],
    // Kabelrännor/Rännor/Armaturskenor
    [
      path + "/040000/040001.png",
      path + "/040000/040002.png",
      path + "/040000/040003.png",
      path + "/040000/040004.png",
      path + "/040000/040005.png",
      path + "/040000/040006.png",
      path + "/040000/040000.all.png",
    ],
    // Trådstegar
    [
      path + "/070000/070001.png",
      path + "/070000/070002.png",
      path + "/070000/070003.png",
      path + "/070000/070005.png",
      path + "/070000/070006.png",
      path + "/070000/070000.all.png",
    ],
    // Fönsterbänkskanaler
    [
      path + "100000/100001.png",
      path + "100000/100002.png",
      path + "100000/100003.png",
      path + "100000/100000.all.png",
    ],
    // Uttagsstavar/poster
    [
      path + "/050000/050001.png",
      path + "/050000/050000.all.png",
    ],
    // Golvboxar
    [
      path + "/110000/110001.png",
      path + "/110000/110002.png",
      path + "/110000/110000.all.png",
    ],
    // Belysningsskenor
    [
      path + "/020000/020001.png",
      path + "/020000/020002.png",
      path + "/020000/020003.png",
      path + "/020000/020000.all.png",
    ],
    // 19" stativ
    [
      path + "/080000/080001.png",
      path + "/080000/080000.all.png",
    ],
    // Profilerskenor
    [
      path + "/060000/060001.png",
      path + "/060000/060000.all.png",
    ],
    // Kabelhållare
    [
      path + "/030000/030001.png",
      path + "/030000/030000.all.png",
    ],
    // Betongskruv
    [
      path + "/120000/120000.all.png"
    ],
    // Potentialutjamning
    [
      path + "/090000/090000.all.png"
    ],
]
  
export {
  CategoryKeys, CategoryImages
}

import Base from './base'

import Lightbox from './../components/lightbox'
import flipSelect from './../components/flip-select'


export default class Product extends Base {
    constructor() {
        super({
            id: 'product',
        })
    }

    init() {
        
        console.log('🚀App:product')
        
        Lightbox();

        flipSelect();

        // Selects
        let dropdowns = document.querySelectorAll('.c-dropdown')


        function handleTransition(e){

            //console.log(e)
            

        };

        
        if (dropdowns) {

            let currentDropdown = null;

            dropdowns.forEach(dropdown => {

                let label = dropdown.querySelector('.c-dropdown-label')
                let active = dropdown.querySelector('.c-dropdown-list-item--active .c-dropdown-list-item__text')
                if (active) {
                    label.innerText = active.innerText
                }

                dropdown.addEventListener('click', (e) => {
                    
                    if (currentDropdown != dropdown && currentDropdown) {
                        currentDropdown.classList.remove('--active')
                        currentDropdown.classList.remove('--is-open')
                        currentDropdown = null
                    }

                    if (dropdown.classList.contains('--active')) {
                        setTimeout(() => {
                            dropdown.classList.remove('--is-open')
                        }, 200);
                    }

                    dropdown.classList.toggle('--active')
                    dropdown.classList.add('--is-open')

                    currentDropdown = dropdown

                });

                // Loop each items
                let listItems = dropdown.querySelectorAll('.c-dropdown-list-item__text')

                listItems.forEach(listItem => {
                    listItem.addEventListener('click', (e) => {
                        label.innerText = listItem.innerText
                        currentDropdown = null
                    });
    
                })


                document.body.addEventListener('click', function(event){
                    
                    if (currentDropdown) {
                        if (!currentDropdown.contains(event.target)){
                            currentDropdown.classList.remove('--active')
                            currentDropdown.classList.remove('--is-open')
                            currentDropdown = null
                        }                        
                    }


                    
                    
                })

            })

            
        }

    }
}

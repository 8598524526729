import Base from './base'

export default class Start extends Base {
    constructor() {
        super({
            id: 'start',
        })
    }

    init() {
        
        console.log('🚀App:start')
        
        
    }
}

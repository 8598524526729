// Vendors
import MMenu from './../components/menu';

export default class Base {
    constructor({
        id,
    }) {
        this.id = id
    }

}





// Default functions

// MM menu
MMenu()

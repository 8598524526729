import Base from './base'
import flipModal from './../components/flip-modal'
import parallax from './../components/parallax'


export default class Page extends Base {
    constructor() {
        super({
            id: 'page',
        })
    }

    init() {
        
        console.log('🚀App:page start')
        
        
        flipModal()

        parallax()

        let links = document.querySelectorAll('.page-kabelforlaggning-kontaktpersoner .b-shortcuts-item, .page-en-cable-management-contact-persons .b-shortcuts-item')

        
        if (links.length > 0) {
            
            let sections = document.querySelectorAll('.b-persons')
            let yOffset = 100
            
            links.forEach( (item, index) => {
                

                item.addEventListener('click', (e) => {
                    e.preventDefault()

                    let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;                    
                    let pos = sections[index].getBoundingClientRect();

                    let y = pos.top + scrolltop + window.scrollY - yOffset

                    document.body.scrollTo( {
                        top: y,
                        behavior: 'smooth'
                    });
                })
                
            });

        }
    }
}
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { debounce } from "./../../scripts/utils/debounce.js"

gsap.registerPlugin(ScrollTrigger);


// https://greensock.com/forums/topic/32692-scrolltrigger-parallax-backgrounds-how-to-avoid-white-around-the-image/

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let elements = {
    speed : 0.25,
    //parallaxes : document.querySelectorAll('.b-hero-media__img, .b-page-intro-media__img, .b-teaser-media__img, .b-text-img-media__img')
    parallaxes : document.querySelectorAll('.b-hero-media__img, .b-page-intro-media__img, .b-teaser-media__img')

}

function fitToParent(target, marginFactor) {

    // Clear in order to reposition
    gsap.set(target, {clearProps: 'all'});

    let scaleX = target.parentNode.offsetWidth / target.offsetWidth
    let scaleY = target.parentNode.offsetHeight * (1 + Math.abs(marginFactor)) / target.offsetHeight;
    let scale = Math.max(scaleX, scaleY);
    let w = Math.ceil(target.offsetWidth * scale);
    let h = Math.ceil(target.offsetHeight * scale);

    gsap.set(target, {width: w, height: h, top: Math.ceil((target.parentNode.offsetHeight - h) / 2), left: Math.ceil((target.parentNode.offsetWidth - w) / 2)});
  }


function init() {

    elements.parallaxes.forEach((layer, i) => {

        fitToParent(layer, elements.speed);

        let isInView = isInViewport(layer.parentElement);
        
        
        //console.log(isInView)

        if(layer.parentElement.classList.contains('b-page-intro-media')) {
            isInView = true
            // console.log('parent', layer.parentElement)
        }

        //console.log(isInView)

        gsap.fromTo(layer, {
            y: () => isInView ? 0 : -elements.speed * 0.5 * layer.parentNode.offsetHeight
        }, {
            y: () => elements.speed * 0.5 * layer.parentNode.offsetHeight,
            ease: "none",
            scrollTrigger: {
                trigger: layer.parentNode,
                //start: () => i ? "top bottom" : "-1px top", 
                //start: "top bottom",
                start: () => isInView ? "top 200%" : "top bottom",
                end: "bottom top",
                scrub: 0.5,
                invalidateOnRefresh: true // to make it responsive
            }
        });

    });

    window.addEventListener("resize", debounce( () => {

        elements.parallaxes.forEach((layer) => {
             fitToParent(layer, elements.speed);
        })

    }, 150 ));


}


export default () => {
    init();
}

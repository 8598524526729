export default () => {

    

    let thumbs = document.querySelectorAll('.c-lightbox-thumbs-media')
    let images = document.querySelectorAll('.c-lightbox-media')


    let activeThumb = thumbs[0]
    let activeImage = images[0]

    thumbs.forEach( (item, index) => {

        // get attribute
        item.setAttribute('data-lightbox-target', index)
        
        // bimd events
        item.addEventListener('click', (e) => {
            
            let target = item.getAttribute('data-lightbox-target');
            
            // set active
            if ( activeThumb != thumbs[target] ) {
                activeThumb.classList.remove('--active')
                activeImage.classList.remove('--active')

                activeThumb = thumbs[target]
                activeImage = images[target]

                activeThumb.classList.add('--active')
                activeImage.classList.add('--active')
            }
    
        })
    })
}
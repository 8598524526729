// https://www.youtube.com/watch?v=byyHoLPSxSQ
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import { debounce } from "./../../scripts/utils/debounce.js"
gsap.registerPlugin(Flip);


let active = {
    modal: null,
    image: null,
    backdrop: null
}

let modalPadding = 50;

let elements = {
    header: document.querySelectorAll('.header'),

    items: document.querySelectorAll('.js-flipmodal'),
    modalClose: document.querySelector('.c-flipmodal-close'),

    // Flip
    modal: document.querySelector('.c-flipmodal'),
    //modalImage: document.querySelector('.c-flipmodal-image'),
    modalInner: document.querySelector('.c-flipmodal-inner'),

    // Content
    modalFadeInElements: document.querySelectorAll('.js-flipmodal-fadein'),
    //modalHeadline: document.querySelector('.c-flipmodal-header__headline'),
    modalInfo: document.querySelector('.c-flipmodal-info')
}


function init() {

    // If empty
    if(!elements.items.length > 0) return
    
    elements.modalClose = document.querySelector('.c-flipmodal-close')

    //Setup modals
    elements.items.forEach((modal) => {

        let button = modal.querySelector('.o-btn')

        if (button) {
            button.addEventListener('click', (e) => {
                if (!active.modal)
                    openModal(modal)
            })
        }
        
    })

    // Close
    elements.modalClose.addEventListener('click', (e) => {
        closeModal();
    })

    // Handle Resize
    // window.addEventListener("resize", debounce(() => {
    //     setPositions()
    // }, 150));

    // Create overlay
    const overlay = document.createElement('div')
    overlay.classList.add('flip-overlay')
    elements.modal.prepend(overlay)

}



function closeModal() {

    document.body.classList.remove('flip-overlay')
    elements.modal.classList.remove('active')
            elements.modal.classList.remove('--has-open')
    active.backdrop.setAttribute('data-testar', 'peter')

    

    const state = Flip.getState([active.backdrop])


    //active.imageWrapper.appendChild(active.image);
    active.imageWrapper.appendChild(active.backdrop);

    Flip.from(state, {
        duration: 0.25,
        scale: true,
        absolute: true,
        simple: true,
        ease: "circ.out",
        toggleClass: "flipping",
        
        onComplete: () => {
            elements.modalInner.scrollTop = 0;
            active.modal = null;
            

            
            const tl = gsap.timeline();
            tl.to(elements.header, {opacity: 1}, 0.15)

        }
    })



}



function openModal(item) {

    // add class to body
    document.body.classList.add('flip-overlay')

    // Copy content
    let title = item.querySelector('.c-person__headline');
    let info = item.querySelector('.c-person-info');

    //elements.modalHeadline.innerHTML = title.innerHTML
    elements.modalInfo.innerHTML = info.innerHTML

    gsap.set(elements.modalFadeInElements, { opacity: 0 })

    // Animate header
    gsap.to(elements.header, {opacity: 0} , 0.15)

    // Transition elements
    active.imageWrapper = item.querySelector('.c-person-image-container');
    active.backdrop = item.querySelector('.c-person-image__backdrop');
    //active.image = item.querySelector('.c-person-image');
    //setPositions()

    const state = Flip.getState([active.backdrop])

    elements.modal.classList.add('active')
    elements.modalInner.appendChild(active.backdrop);
    //elements.modalImage.appendChild(active.image);

    Flip.from(state, {
        duration: .25,
        scale: true,
        absolute: true,
        simple: true,
        nested: true,
        ease: "circ.in",
        toggleClass: 'flipping',
        onEnter: () => {
           
            //console.log('r')
        },
        onComplete: () => {
            elements.modal.classList.add('--has-open')
            //gsap.to(elements.header, {opacity: 0}, 0.25)
            elements.modalInner.scrollTop = 0;
            //gsap.to('.c-flipmodal-inner', {opacity: 1}, 0.25)

        }
    }).to(elements.modalFadeInElements, { opacity: 1}, 0.25)


    active.modal = item

}


export default () => {
    init();
}
